<template>
  <div @click="closeModal" @touchmove.prevent class="download-modal">
    <div class="download-modal-container">
      <div class="title">打开快音，享受更多功能互动与体验。</div>
      <button id="downloadBtn" @click="openApp" class="btn">打开/下载快音</button>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Download from '@/utils/download';
  export default {
    name: 'DownloadModal',
    props: {
      changeDownloadModalShow: {
        type: Function,
        default: () => {},
      },
      umLink: {
        type: Object,
        default: () => {},
      },
    },
    computed: {
      ...mapGetters('share/togetherWatch', ['InviteCode']),
    },
    mounted() {
      this.$track({
        page_title: '一起听分享落地页',
        element_name: '弹窗_曝光',
      });
    },
    methods: {
      openApp() {
        this.$track({
          page_title: '一起听分享落地页',
          element_name: '弹窗_打开 或者是 弹窗_下载',
        });
        let jump = () => {
          let url;

          if (this.$platform.Android) {
            url = Download.downLoad('yyb');
          } else if (this.$platform.iOS) {
            url = Download.downLoad('share_ios');
          } else {
            url = Download.downLoad('yyb');
          }
          window.location.href = url;
        };
        // 使用友盟 U-link
        if (this.umLink.isUmLinkInitSuccess) {
          return;
        }
        window.location.href = `kuaiyin://followRoomDetail?roomCode=${this.$route.query.room_code}&startColor=${this.$route.query.startColor}&endColor=${this.$route.query.endColor}`;
        setTimeout(() => {
          jump();
        }, 1000);
      },
      closeModal() {
        this.changeDownloadModalShow(false);
      },
    },
  };
</script>

<style scoped lang="less">
  .download-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    &-container {
      background: #fff;
      width: 6rem;
      border-radius: 0.12rem;
      padding: 0.4rem;
      box-sizing: border-box;
      .title {
        font-size: 0.36rem;
        color: #333333;
        font-weight: bold;
        text-align: center;
      }
      .btn {
        width: 5.2rem;
        height: 0.8rem;
        border-radius: 0.4rem;
        background: #fa3123;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.32rem;
        font-weight: bold;
        border: none;
        margin-top: 0.2rem;
      }
    }
  }
</style>
