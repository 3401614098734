<template>
  <div
    @click="copyCode"
    id="download"
    :data-clipboard-text="InviteCode"
    class="together-watch"
    :style="{ background: `linear-gradient(180deg, #${startColor} 0%, #${endColor} 100%)` }"
  >
    <template v-if="RoomInfo.status === '1'">
      <div class="together-watch-top-warp">
        <img class="control-bg" src="@/assets/images/together-watch/control-bg.png" alt="bg" />
        <div class="top-warp-content">
          <div @click="changeDownloadModalShow(true)" class="room-user">
            <img class="user-img" :src="UserInfo.avatar_small" alt="" />
            <div class="user-title">房主</div>
          </div>
          <div class="control-warp">
            <img class="music-bg" :src="MusicInfo.video_cover || MusicalInfo.avatar_small" alt="" />
            <div class="live-num">
              <div class="point"></div>
              {{ RoomInfo.online_num }}人在线
            </div>
            <div class="music-title-warp">
              <img
                v-show="!isMusicPlay"
                @click="handleMedia"
                src="@/assets/images/together-watch/stop.png"
                alt="icon"
              />
              <img v-show="isMusicPlay" @click="handleMedia" src="@/assets/images/together-watch/play.png" alt="icon" />

              <div ref="musicTitle" class="music-title">{{ MusicName }}</div>
              <div ref="musicTitleHidden" class="music-title-hidden">{{ MusicName }}</div>

              <img @click="changeDownloadModalShow(true)" src="@/assets/images/together-watch/xin.png" alt="icon" />
            </div>
          </div>
        </div>
      </div>
      <div class="together-watch-bot-warp">
        <div class="bot-warp-tab">
          <div @click="changeDownloadModalShow(true)">留言</div>
          <div class="tab-lyric">歌词</div>
        </div>
        <div class="song-name">{{ MusicInfo.music_name }}</div>
        <!--        -->
        <div v-if="MusicInfo.has_lrc === '1'" class="lyric-warp">
          <div class="lyric-mask"></div>
          <div
            :style="{ top: `${(index + 1 - currentLyricIndex) * 0.77}rem` }"
            :class="['lyric-item', 'one-line-ellipsis', currentLyricIndex === index && 'active']"
            v-for="(item, index) in LyricsList"
            :key="index"
          >
            {{ item.text }}
          </div>
        </div>
        <div class="without-lyric" v-else>暂无歌词...</div>
      </div>
      <div class="bottom-warp">
        <div class="input-container">
          <img class="icon" src="@/assets/images/together-watch/edit.png" alt="icon" />
          说点什么吧…
        </div>
        <div @click="changeDownloadModalShow(true)" class="icon-item">
          <img src="@/assets/images/together-watch/like.png" alt="icon" />
        </div>
        <div @click="changeDownloadModalShow(true)" class="icon-item">
          <img src="@/assets/images/together-watch/music.png" alt="icon" />
        </div>
        <div @click="changeDownloadModalShow(true)" class="icon-item">
          <img class="music-bg" src="@/assets/images/together-watch/music-bg.png" alt="icon" />
          <img class="music-cover" :src="MusicInfo.video_cover || MusicalInfo.avatar_small" alt="cover" />
        </div>
      </div>
      <audio preload loop ref="music_dom" id="music_dom" :src="MusicInfo.cdn_url">你的浏览器暂不支持audio标签</audio>
      <DownloadModal :umLink="umLink" :changeDownloadModalShow="changeDownloadModalShow" v-if="isDownloadModalShow" />
    </template>
    <div v-else class="room-close">
      <img class="user-img" :src="UserInfo.avatar_small" alt="img" />
      <div class="user-name">{{ UserInfo.nickname }}</div>
      <div class="info">本房间已被房主关闭</div>
    </div>
  </div>
</template>

<script>
  import Clipboard from 'clipboard';
  import DownloadModal from '@/views/Welfare/Fission/TogetherWatch/components/DownloadModal';
  import { mapGetters, mapMutations } from 'vuex';
  import InitUmLink from '@/utils/init-ulink';
  export default {
    name: 'index',
    components: {
      DownloadModal,
    },
    async asyncData({ store, $http, params }) {
      const { musicInfo, userInfo, status, online_num, musicalInfo } = await $http.post(
        '/Share/ListenTogetherSharePage',
        {
          ...params,
        }
      );
      console.log(musicInfo);
      let roomInfo = { status, online_num };
      store.commit('share/togetherWatch/SET_MUSIC_INFO', musicInfo || {});
      store.commit('share/togetherWatch/SET_USER_INFO', userInfo || {});
      store.commit('share/togetherWatch/SET_ROOM_INFO', roomInfo || {});
      store.commit('share/togetherWatch/SET_MUSICAL_INFO', musicalInfo || {});
      store.commit('share/togetherWatch/SET_INVITE_CODE', `邀请码【${params.invite_code || ''}】`);

      // 获取歌词
      if (musicInfo && musicInfo.has_lrc === '1' && musicInfo.lrc) {
        const lyricsData = await $http.get(musicInfo.lrc);
        store.commit('share/togetherWatch/SET_ORIGIN_LYRICS_LIST', lyricsData.data);
      }
    },
    computed: {
      ...mapGetters('share/togetherWatch', [
        'MusicInfo',
        'UserInfo',
        'RoomInfo',
        'LyricsList',
        'OriginLyricsList',
        'MusicName',
        'InviteCode',
        'MusicalInfo',
      ]),
    },
    data() {
      return {
        isDownloadModalShow: false,
        isMusicPlay: false,
        currentLyricIndex: 0,
        startColor: 'ffe7e6',
        endColor: 'e0f8ff',
        umLink: {},
      };
    },
    beforeMount() {
      this.startColor = this.$route.query.startColor || 'ffe7e6';
      this.endColor = this.$route.query.endColor || 'e0f8ff';
    },
    mounted() {
      if (this.MusicInfo.has_lrc === '1' && this.MusicInfo.lrc && this.OriginLyricsList) {
        this.initPoem();
        let music_dom = document.getElementById('music_dom');
        // 设置监听歌曲播放
        this.timeupdateListener(music_dom);
      }

      // 判断超出宽度滚动播放
      let musicTitleHiddenWidth = this.$refs['musicTitleHidden'].clientWidth;
      let musicTitleWidth = this.$refs['musicTitle'].clientWidth;
      if (musicTitleHiddenWidth > musicTitleWidth) {
        let interval = setInterval(() => {
          let str = this.MusicName;
          let lastStr = str.substring(1);
          let firstStr = str.substring(0, 1);
          this.SET_MUSIC_NAME(lastStr + firstStr);
        }, 1000);
        this.$on('hook:beforeDestroy', () => {
          clearInterval(interval);
        });
      }

      const selectorStr = '#downloadBtn';
      this.umLink = new InitUmLink({
        selector: selectorStr,
        linkId: 'usr1e5e0vgs8vui8',
        pageTitle: '一起听分享落地页',
        clipboardText: this.InviteCode,
        traceId: this.traceId,
        ext: {
          roomCode: this.$route.query.room_code,
          startColor: this.$route.query.startColor,
          endColor: this.$route.query.endColor,
        },
      });
    },
    methods: {
      ...mapMutations('share/togetherWatch', ['SET_LYRICS_LIST', 'SET_MUSIC_NAME']),
      timeupdateListener(dom) {
        dom.addEventListener('timeupdate', () => {
          let currentTime = dom.currentTime;
          for (let i = 0; i < this.LyricsList.length; i++) {
            if (currentTime >= parseInt(this.LyricsList[i].time)) {
              if (this.LyricsList[i + 1]) {
                if (currentTime < parseInt(this.LyricsList[i + 1].time)) {
                  this.currentLyricIndex = i;
                  break;
                }
              } else {
                // 最后一句
                this.currentLyricIndex = this.LyricsList.length - 1;
                break;
              }
            } else {
              if (currentTime < parseInt(this.LyricsList[0].time)) {
                this.currentLyricIndex = 0;
                break;
              }
            }
          }
        });
      },
      async initPoem() {
        try {
          // 解析歌词
          if (this.OriginLyricsList) {
            const LyricsList = this.OriginLyricsList.split('\n');
            const regTime = /\[\d{2}:\d{2}.\d{2,3}/;
            const lyricsObjArr = [];

            LyricsList.forEach((item) => {
              if (item === '') return;
              const obj = {};
              const time = item.match(regTime);

              obj.lyric = item.split(']')[1].trim() === '' ? '' : item.split(']')[1].trim();
              obj.time = time ? this.formatLyricTime(time[0].slice(1, time[0].length - 1)) : 0;
              if (obj.lyric === '') {
                console.log('这一行没有歌词');
              } else {
                lyricsObjArr.push(obj);
              }
            });
            this.SET_LYRICS_LIST(lyricsObjArr);
          }
        } catch (e) {
          console.log('歌词解析出错~');
        }
      },
      handleMedia() {
        let music_dom = document.getElementById('music_dom');
        if (music_dom.paused) {
          this.isMusicPlay = true;
          music_dom.play();
        } else {
          this.isMusicPlay = false;
          music_dom.pause();
        }
      },
      changeDownloadModalShow(flag) {
        this.isDownloadModalShow = flag;
      },
      copyCode() {
        new Clipboard('#download');
      },
    },
  };
</script>

<style scoped lang="less">
  .together-watch {
    width: 100%;
    min-height: 100vh;
    //background: linear-gradient(180deg, #ffe7e6 0%, #e0f8ff 100%);
    display: flex;
    flex-direction: column;
    user-select: none;
    .room-close {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .user-img {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
      }
      .user-name {
        font-size: 0.36rem;
        font-weight: bold;
        color: #1a1a1a;
        margin-top: 0.2rem;
      }
      .info {
        font-size: 0.26rem;
        color: #666666;
      }
    }
    &-top-warp {
      height: 7.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .top-warp-content {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .control-warp {
          position: relative;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .music-bg {
            width: 1.56rem;
            height: 1.56rem;
            border-radius: 50%;
          }
          .live-num {
            font-size: 0.2rem;
            background: #6dd400;
            line-height: 0.28rem;
            border-radius: 0.14rem;
            padding: 0 0.12rem;
            color: #fff;
            position: absolute;
            top: 4.26rem;
            display: flex;
            align-items: center;
            .point {
              width: 0.08rem;
              height: 0.08rem;
              border-radius: 50%;
              background: #fff;
              margin-right: 0.06rem;
            }
          }
        }
        .room-user {
          font-size: 0.2rem;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 0.7rem;
          z-index: 9;
          .user-title {
            background: #fff;
            width: 0.64rem;
            display: flex;
            justify-content: center;
            border-radius: 0.15rem;
            position: absolute;
            bottom: -0.2rem;
            left: 50%;
            transform: translateX(-50%);
          }
          .user-img {
            width: 0.9rem;
            height: 0.9rem;
            border-radius: 50%;
            border: none;
          }
        }
        .music-title-warp {
          display: flex;
          align-items: center;
          position: absolute;
          height: 0.48rem;
          border-radius: 0.24rem;
          padding: 0 0.1rem;
          background: linear-gradient(270deg, #ffe7e6 0%, #e0f8ff 100%);
          border: 0.04rem solid #ffffff;
          img {
            width: 0.32rem;
            height: 0.32rem;
          }
          .music-title {
            line-height: 0.48rem;
            width: 1.94rem;
            text-align: center;
            overflow: hidden;
            font-size: 0.24rem;
            font-weight: bold;
            color: #1a1a1a;
            white-space: nowrap;
            margin: 0 0.1rem;
          }
          .music-title-hidden {
            font-size: 0.24rem;
            font-weight: bold;
            color: #1a1a1a;
            white-space: nowrap;
            //margin: 0 0.1rem;
            position: absolute;
            z-index: -999;
            top: -9999px;
          }
        }
      }
      .control-bg {
        height: 6.7rem;
        width: 6.7rem;
      }
    }
    &-bot-warp {
      flex: 1;
      .bot-warp-tab {
        padding: 0 0.3rem;
        display: flex;
        font-size: 0.32rem;
        color: #666666;
        .tab-lyric {
          border-left: 0.01rem solid #666666;
          font-weight: bold;
          color: #1a1a1a;
          position: relative;
          margin-left: 0.3rem;
          width: 1.3rem;
          &::after {
            display: block;
            content: '';
            width: 0.28rem;
            height: 0.08rem;
            border-radius: 0.04rem;
            background: #fa3123;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
          }
        }

        div {
          //width: 1.2rem;
          text-align: center;
        }
      }
      .song-name {
        font-size: 0.32rem;
        color: #666666;
        margin: 0.4rem 0;
        padding: 0 0.3rem;
      }
      .lyric-warp {
        height: 3.86rem;
        padding: 0 0.3rem;
        box-sizing: border-box;
        font-size: 0.32rem;
        color: #666666;
        position: relative;
        overflow: hidden;
        .lyric-mask {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: linear-gradient(
            rgba(250, 250, 250, 0) 0%,
            rgba(250, 250, 250, 0.4) 10%,
            rgba(250, 250, 250, 0) 21%,
            rgba(250, 250, 250, 0) 40%,
            rgba(255, 255, 255, 0.5) 90%,
            rgba(255, 255, 255, 0) 100%
          );
        }
        .lyric-item {
          width: 100%;
          height: 0.77rem;
          line-height: 0.77rem;
          filter: blur(0.01rem);
          transition: top 0.5s;
          position: absolute;
          &.active {
            font-size: 0.48rem;
            font-weight: bold;
            color: #333333;
            filter: unset;
          }
        }
      }
      .without-lyric {
        line-height: 3.86rem;
        font-size: 0.32rem;
        color: #666666;
        padding: 0 0.3rem;
      }
    }
    .bottom-warp {
      display: flex;
      padding: 0.4rem 0.3rem 0.9rem;
      justify-content: space-between;
      .input-container {
        width: 4.14rem;
        height: 0.8rem;
        border-radius: 0.4rem;
        background: rgba(255, 255, 255, 0.6);
        font-size: 0.28rem;
        color: #a6a6a6;
        display: flex;
        align-items: center;
        padding: 0 0.2rem;
        box-sizing: border-box;
        .icon {
          width: 0.32rem;
          height: 0.32rem;
        }
      }
      .icon-item {
        width: 0.8rem;
        height: 0.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.6);
        position: relative;
        img {
          width: 0.4rem;
          height: 0.4rem;
        }
        .music-bg {
          width: 0.8rem;
          height: 0.8rem;
        }
        .music-cover {
          background: #fff;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
        }
      }
    }
  }
</style>
